<template>
  <div>
    <v-col cols="12">
      <p class="text-body-1" v-if="questionFieldHasBeenSet">
        {{ index + 1 + ". " + $t(question) }}
      </p>
    </v-col>
    <v-col
      cols="12"
      :offset="questionFieldHasBeenSet && $vuetify.breakpoint.mdAndUp ? 1 : 0"
      class="pb-0 mb-0"
    >
      <v-row justify="center" class="selectorContainer">
        <v-slide-group
          v-model="painLevel"
          center-active
          show-arrows
          prev-icon="mdi-minus"
          next-icon="mdi-plus"
        >
          <v-slide-item
            v-for="i in painTitles.length"
            :key="i"
            v-slot:default="{ active, toggle }"
          >
            <div
              @mouseover="painLevel = i - 1"
              @mouseleave="painLevel = value"
              @click="emitToParent(i - 1)"
              style="cursor: pointer"
            >
              <p
                class="text-body-1 text-center ma-0 pa-0"
                v-show="i - 1 === painLevel"
              >
                <b>{{ i - 1 }}</b>
              </p>
              <v-img
                contain
                class="ma-1"
                :max-width="getEmojiSize(i - 1)"
                :src="
                  require('@/assets/images/emojis/painLevel' + (i - 1) + '.png')
                "
                @click="toggle"
              >
              </v-img>
            </div>
          </v-slide-item>
        </v-slide-group>
        <v-col
          cols="12"
          class="pa-0 mt-3 text-body-2 text-center"
          style="min-height: 80px"
        >
          <p v-if="painLevel">
            <b>{{ $t(painTitles[painLevel]) }}</b> <br />
            {{ painDescriptions[painLevel] }}
          </p>
          <p
            v-else-if="!questionFieldHasBeenSet && !!value"
            class="vitrueDarkGrey--text"
          >
            {{ $t("form.painLevel.noneSelected") }}
          </p>
        </v-col>
      </v-row>
      <v-input :rules="rules" />
    </v-col>
  </div>
</template>

<script>
import PainLevelTitles from "@/assets/json/DeskAssessment/PainLevelTitles.json";
export default {
  name: "PainLevelQuestion",
  props: {
    name: String,
    question: String,
    value: {
      type: Number
    },
    index: Number,
    valueCanBeZero: Boolean
  },
  computed: {
    valueCanBeZeroInputRule() {
      return [
        Number.isInteger(this.value) || this.$t("form.painLevel.noneSelected")
      ];
    },
    valueCantBeZeroInputRule() {
      return [this.value > 0 || this.$t("form.painLevel.selectArea")];
    },
    rules() {
      return this.valueCanBeZero
        ? this.valueCanBeZeroInputRule
        : this.valueCantBeZeroInputRule;
    },
    questionFieldHasBeenSet() {
      // this component was originally designed to sit in a questionnaire, we now want to use it outside of
      // that and to change some settings. No new prop was added, just relying on whether the
      // question prop is set to decide if this is being used in a questionnaire or not
      return !!this.question;
    }
  },
  mounted() {
    let valueToEmit = this.value;
    let setDefaultValue = this.valueCanBeZero
      ? !Number.isInteger(this.value)
      : !this.value;

    if (setDefaultValue) {
      valueToEmit = this.valueCanBeZero ? undefined : 0;
    }
    this.emitToParent(valueToEmit);
  },
  methods: {
    getPainEmoji(index) {
      return require("@/assets/images/emojis/painLevel" + index + ".png");
    },
    getEmojiSize(index) {
      return index === this.painLevel ? "40px" : "20px";
    },
    emitToParent(value) {
      this.$emit("input", value);
    }
  },
  data() {
    return {
      painLevel: this.value,
      hoverLevel: this.value,
      painTitles: PainLevelTitles,
      painDescriptions: [
        this.$t("form.painLevel.level0"),
        this.$t("form.painLevel.level1"),
        this.$t("form.painLevel.level2"),
        this.$t("form.painLevel.level3"),
        this.$t("form.painLevel.level4"),
        this.$t("form.painLevel.level5"),
        this.$t("form.painLevel.level6"),
        this.$t("form.painLevel.level7"),
        this.$t("form.painLevel.level8"),
        this.$t("form.painLevel.level9"),
        this.$t("form.painLevel.level10")
      ]
    };
  }
};
</script>

<style scoped>
.selectorContainer {
  max-width: 350px;
}
</style>
