import DeskAssessmentUtil from "@/services/deskassessment/desk-assessment-results-util";
export default {
  getAssessmentDataPointValuesForHealth(
    index,
    schema,
    key,
    value,
    items,
    results
  ) {
    for (var i = 0; i < schema[index - 1].pages.length; i++) {
      let question = schema[index - 1].pages[i].questions.find(
        a => a.name == key
      );

      if (question.fieldType == "BMIQuestion") {
        value.Score = DeskAssessmentUtil.getBMIScore(results.health.bmi.bmi);
      }

      this.ensureQuestionExistsAndAddToData(question, value, items, key);
    }
  },
  getAssessmentDataPointValuesForPain(
    index,
    schema,
    painKey,
    key,
    value,
    items
  ) {
    for (var i = 0; i < schema[index - 1].pages.length; i++) {
      let question = schema[index - 1].pages[i].questions[painKey].find(
        a => a.name == key
      );
      this.ensureQuestionExistsAndAddToData(
        question,
        value,
        items,
        key,
        painKey
      );
    }
  },
  getAssessmentDataPointValues(
    index,
    schema,
    pageKey,
    key,
    value,
    items,
    group
  ) {
    let page = schema[index - 1].pages[pageKey];
    let question = page.questions.find(a => a.name == key);
    this.ensureQuestionExistsAndAddToData(question, value, items, key, group);
  },
  addDeskPlanObjectsToData(objects, items, group) {
    if (objects.length === 0) {
      // If there are no objects, still push an item with no value
      // to ensure items are deleted if necessary
      items.push({
        Name: "VisualDeskPlanner",
        Group: group,
        FieldType: "VisualDeskPlanner",
        Value: {}
      });
    }
    for (let i = 0; i < objects.length; i++) {
      items.push({
        Name: "VisualDeskPlanner",
        Group: group,
        FieldType: "VisualDeskPlanner",
        Value: {
          ItemId: objects[i].id,
          Version: objects[i].version,
          Width: objects[i].width,
          Height: objects[i].height,
          X: objects[i].x,
          Y: objects[i].y,
          Rotation: objects[i].rotation,
          ZIndex: objects[i].zIndex
        }
      });
    }
  },
  addDeskPlanImageUrlToData(image, items, group) {
    if (!image) {
      return;
    }
    items.push({
      Name: "VisualDeskPlannerImage",
      Group: group,
      FieldType: "VisualDeskPlannerImage",
      Value: image
    });
  },
  ensureQuestionExistsAndAddToData(question, value, items, key, group) {
    if (question) {
      if (
        question.fieldType == "RadioButtonQuestion" ||
        question.fieldType == "VerticalRadioButtonQuestion"
      ) {
        let score = null;
        if (question.options && question.scores) {
          let scoreIndex = question.options.findIndex(a => a.value == value);
          score = question.scores[scoreIndex];
        }
        let valIsInt = Number.isInteger(value);
        items.push({
          Name: key,
          Group: group,
          FieldType: question.fieldType,
          Value: valIsInt
            ? { IntValue: value, Score: score }
            : { FloatValue: value, Score: score }
        });
      }
      if (question.fieldType == "CheckBoxListQuestion") {
        let valuesArray = [];
        value.forEach(option => {
          let score = null;
          if (question.options && question.scores) {
            let scoreIndex = question.options.findIndex(a => a.value == option);
            score = question.scores[scoreIndex];
          }
          valuesArray.push({ IntValue: option, Score: score });
        });
        items.push({
          Name: key,
          Group: group,
          FieldType: question.fieldType,
          Value: valuesArray
        });
      }
      if (
        question.fieldType == "SliderQuestion" ||
        question.fieldType == "StringQuestion" ||
        question.fieldType == "PainLevelQuestion" ||
        question.fieldType == "CardSelectQuestion" ||
        question.fieldType == "BMIQuestion"
      ) {
        items.push({
          Name: key,
          Group: group,
          FieldType: question.fieldType,
          Value: value
        });
      }
    }
  },
  getGenericDataPointDTO(group, name, value) {
    return [
      {
        FieldType: "RadioButtonQuestion",
        Group: group,
        Name: name,
        Value: { IntValue: value, Score: null }
      }
    ];
  },
  getPainDataPointDTO(painAreas, painLevel) {
    let values = [];
    painAreas.forEach((element, index) => {
      if (painLevel[index] != null) {
        values.push({
          FieldType: "PainLevelQuestion",
          Group: element,
          Name: "level",
          Value: painLevel[index]
        });
      }
    });
    return values;
  }
};
