var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-col", { attrs: { cols: "12" } }, [
        _vm.questionFieldHasBeenSet
          ? _c("p", { staticClass: "text-body-1" }, [
              _vm._v(
                " " + _vm._s(_vm.index + 1 + ". " + _vm.$t(_vm.question)) + " "
              )
            ])
          : _vm._e()
      ]),
      _c(
        "v-col",
        {
          staticClass: "pb-0 mb-0",
          attrs: {
            cols: "12",
            offset:
              _vm.questionFieldHasBeenSet && _vm.$vuetify.breakpoint.mdAndUp
                ? 1
                : 0
          }
        },
        [
          _c(
            "v-row",
            { staticClass: "selectorContainer", attrs: { justify: "center" } },
            [
              _c(
                "v-slide-group",
                {
                  attrs: {
                    "center-active": "",
                    "show-arrows": "",
                    "prev-icon": "mdi-minus",
                    "next-icon": "mdi-plus"
                  },
                  model: {
                    value: _vm.painLevel,
                    callback: function($$v) {
                      _vm.painLevel = $$v
                    },
                    expression: "painLevel"
                  }
                },
                _vm._l(_vm.painTitles.length, function(i) {
                  return _c("v-slide-item", {
                    key: i,
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var active = ref.active
                            var toggle = ref.toggle
                            return [
                              _c(
                                "div",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    mouseover: function($event) {
                                      _vm.painLevel = i - 1
                                    },
                                    mouseleave: function($event) {
                                      _vm.painLevel = _vm.value
                                    },
                                    click: function($event) {
                                      return _vm.emitToParent(i - 1)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: i - 1 === _vm.painLevel,
                                          expression: "i - 1 === painLevel"
                                        }
                                      ],
                                      staticClass:
                                        "text-body-1 text-center ma-0 pa-0"
                                    },
                                    [_c("b", [_vm._v(_vm._s(i - 1))])]
                                  ),
                                  _c("v-img", {
                                    staticClass: "ma-1",
                                    attrs: {
                                      contain: "",
                                      "max-width": _vm.getEmojiSize(i - 1),
                                      src: require("@/assets/images/emojis/painLevel" +
                                        (i - 1) +
                                        ".png")
                                    },
                                    on: { click: toggle }
                                  })
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                }),
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "pa-0 mt-3 text-body-2 text-center",
                  staticStyle: { "min-height": "80px" },
                  attrs: { cols: "12" }
                },
                [
                  _vm.painLevel
                    ? _c("p", [
                        _c("b", [
                          _vm._v(_vm._s(_vm.$t(_vm.painTitles[_vm.painLevel])))
                        ]),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(
                          " " +
                            _vm._s(_vm.painDescriptions[_vm.painLevel]) +
                            " "
                        )
                      ])
                    : !_vm.questionFieldHasBeenSet && !!_vm.value
                    ? _c("p", { staticClass: "vitrueDarkGrey--text" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("form.painLevel.noneSelected")) +
                            " "
                        )
                      ])
                    : _vm._e()
                ]
              )
            ],
            1
          ),
          _c("v-input", { attrs: { rules: _vm.rules } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }